import { createApp } from 'vue';
import App from './App.vue';
import i18n from './i18n';
import { VueReCaptcha } from 'vue-recaptcha-v3';

const app = createApp(App);

app.use(VueReCaptcha, { siteKey: '6Ld9gCwqAAAAAD5bhdKx5S-mYr9L9mc8V9tCbK2O' });

app.use(i18n);
app.mount('#app');
