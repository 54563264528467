<template>
  <div class="bg-gray-900 text-gray-200">
    <!-- Barra de navegación -->
    <div class="fixed w-full top-0 left-0 z-50 bg-gray-700 shadow-lg">
      <nav class="container flex justify-between items-center px-4 py-3">
        <div class="flex items-center">
          <img src="@/assets/Logo3Transparente.png" alt="Logo" class="h-12 mr-3">
        </div>
        <div class="hidden md:flex justify-center flex-1">
          <a class="text-white font-bold mx-3 cursor-pointer hover:text-yellow-400" href="#home" @click.prevent="scrollToSection('home')">{{ $t('navbar.home') }}</a>
          <a class="text-white font-bold mx-3 cursor-pointer hover:text-yellow-400" href="#services" @click.prevent="scrollToSection('services')">{{ $t('navbar.services') }}</a>
          <a class="text-white font-bold mx-3 cursor-pointer hover:text-yellow-400" href="#about" @click.prevent="scrollToSection('about')">{{ $t('navbar.about') }}</a>
          <a class="text-white font-bold mx-3 cursor-pointer hover:text-yellow-400" href="#contact" @click.prevent="scrollToSection('contact')">{{ $t('navbar.contact') }}</a>
        </div>
        <div class="flex">
          <a class="text-white font-bold mx-2 cursor-pointer" @click="changeLanguage('es')" :class="{ 'text-yellow-400': currentLanguage === 'es' }">ES</a>
          <a class="text-white font-bold mx-2 cursor-pointer" @click="changeLanguage('sv')" :class="{ 'text-yellow-400': currentLanguage === 'sv' }">SV</a>
          <a class="text-white font-bold mx-2 cursor-pointer" @click="changeLanguage('ru')" :class="{ 'text-yellow-400': currentLanguage === 'ru' }">RU</a>
          <a class="text-white font-bold mx-2 cursor-pointer" @click="changeLanguage('en')" :class="{ 'text-yellow-400': currentLanguage === 'en' }">EN</a>
        </div>
      </nav>
    </div>

    <!-- Sección principal -->
    <div class="pt-20">
      <section id="home" class="container bg-gray-800 text-gray-200 py-10 rounded-lg mx-4 scroll-mt-12">
        <header class="bg-blue-200 text-center py-6 px-4 rounded-lg mx-auto" style="max-width: 600px;">
          <img src="@/assets/Logo3Transparente.png" alt="Stabilitex Redovisningsbyrå AB" class="mx-auto mb-4 h-32 w-auto">
          <p class="text-xl text-black">{{ $t('header.subtitle') }}</p>
        </header>
        <div class="text-center mt-4">
          <p class="px-4">{{ $t('header.description') }}</p>
        </div>
      </section>

      <!-- Sección de servicios (imágenes incluidas) -->
      <section id="services" class="container my-14">
        <h2 class="text-3xl font-bold text-center text-gray-200 mb-8">{{ $t('services.title') }}</h2>
        <div class="grid grid-cols-1 md:grid-cols-2 gap-8">
          <div v-for="(service, index) in translatedServices" :key="index" class="flex items-center bg-gray-700 rounded-lg shadow-lg p-6"
               :class="{'md:col-span-2': index === translatedServices.length - 1 && translatedServices.length % 2 !== 0}">
            <img v-if="index < 4" :src="service.image" :alt="service.title" class="w-32 h-auto rounded-lg mr-6">
            <div class="text-gray-300">
              <p class="font-bold text-yellow-400 mb-2">{{ service.title }}</p>
              <p>{{ service.description }}</p>
            </div>
          </div>
        </div>
      </section>

      <!-- Sección sobre nosotros (sin imágenes) -->
      <section id="about" class="container bg-gray-800 text-gray-200 py-10 rounded-lg mx-4 mt-14">
        <h2 class="text-3xl font-bold text-center mb-8">{{ $t('about.title') }}</h2>
        <div class="grid grid-cols-1 md:grid-cols-2 gap-8">
          <div v-for="(aboutItem, index) in translatedAbout" :key="index" class="flex items-center bg-gray-700 rounded-lg shadow-lg p-6"
               :class="{'md:col-span-2': index === translatedAbout.length - 1 && translatedAbout.length % 2 !== 0}">
            <div class="text-gray-300">
              <p class="font-bold text-yellow-400 mb-2">{{ aboutItem.title }}</p>
              <p>{{ aboutItem.description }}</p>
            </div>
          </div>
        </div>
      </section>

      <!-- Sección de contacto (sin imágenes) -->
      <section id="contact" class="container my-14">
        <h2 class="text-3xl font-bold text-center text-gray-200 mb-8">{{ $t('contact.title') }}</h2>
        <p class="text-center text-gray-400 mb-8">{{ $t('contact.description') }}</p>

        <form @submit.prevent="sendEmail" class="max-w-lg mx-auto">
          <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div>
              <label for="nombre" class="block font-bold text-gray-300">{{ $t('contact.name') }}</label>
              <input type="text" id="nombre" v-model="contactForm.nombre" class="w-full p-2 border rounded bg-gray-800 text-gray-200" required />
            </div>
            <div>
              <label for="apellido" class="block font-bold text-gray-300">{{ $t('contact.lastName') }}</label>
              <input type="text" id="apellido" v-model="contactForm.apellido" class="w-full p-2 border rounded bg-gray-800 text-gray-200" required />
            </div>
          </div>
          <div class="grid grid-cols-1 md:grid-cols-2 gap-4 mt-4">
            <div>
              <label for="telefono" class="block font-bold text-gray-300">{{ $t('contact.phone') }}</label>
              <input type="tel" id="telefono" v-model="contactForm.telefono" class="w-full p-2 border rounded bg-gray-800 text-gray-200" required />
            </div>
            <div>
              <label for="correo" class="block font-bold text-gray-300">{{ $t('contact.email') }}</label>
              <input type="email" id="correo" v-model="contactForm.correo" class="w-full p-2 border rounded bg-gray-800 text-gray-200" required />
            </div>
          </div>
          <div class="mt-4">
            <label for="mensaje" class="block font-bold text-gray-300">{{ $t('contact.message') }}</label>
            <textarea id="mensaje" v-model="contactForm.mensaje" rows="4" class="w-full p-2 border rounded bg-gray-800 text-gray-200"></textarea>
          </div>
          <div class="text-center mt-6">
            <button type="submit" class="bg-yellow-500 text-gray-900 px-6 py-2 rounded hover:bg-yellow-400">{{ $t('contact.send') }}</button>
          </div>
        </form>

        <!-- Mensaje de éxito o error -->
        <p v-if="emailStatus === 'success'" class="text-green-500 text-center font-bold mt-4">{{ $t('contact.successMessage') }}</p>
        <p v-if="emailStatus === 'error'" class="text-red-500 text-center font-bold mt-4">{{ $t('contact.errorMessage') }}</p>

        <p class="text-center font-bold mt-6 text-gray-300">{{ $t('contact.contactNumber') }}</p>
        <p class="text-center font-bold text-gray-300">info@stabilitexredovisning.se</p>
      </section>
    </div>
  </div>
</template>

<script>
import Asesoramiento from '@/assets/Images/Asesoramiento.jpg';
import Asesoramientovirtual from '@/assets/Elegirnos/ServicioPersonalizado.webp';
import Contabilidad from '@/assets/Images/Nominas.webp';
import Nominas from '@/assets/Elegirnos/Experiencia.jpg';
import Reportes from '@/assets/Images/Asesoramiento.jpg';

import Experiencia from '@/assets/Elegirnos/ServicioPersonalizado.webp';
import Fiabilidad from '@/assets/Images/Nominas.webp';
import ServicioPersonalizado from '@/assets/Elegirnos/Experiencia.jpg';

import emailjs from 'emailjs-com';
import { useReCaptcha } from 'vue-recaptcha-v3';

import { ref, reactive } from 'vue';


export default {
  name: 'PrincipalView',
  data() {
    return {
      currentLanguage: 'sv', // Idioma inicial
      serviceImages: [
        Asesoramiento,
        Asesoramientovirtual,
        Contabilidad,
        Nominas,
        Reportes,
      ],
      aboutImages: [
        Experiencia,
        ServicioPersonalizado,
        Fiabilidad,
      ],
    };
  },
  computed: {
    translatedServices() {
      return [
        {
          title: this.$t('services.service1'),
          description: this.$t('services.service1Description'),
          image: this.serviceImages[0],
        },
        {
          title: this.$t('services.service2'),
          description: this.$t('services.service2Description'),
          image: this.serviceImages[1],
        },
        {
          title: this.$t('services.service3'),
          description: this.$t('services.service3Description'),
          image: this.serviceImages[2],
        },
        {
          title: this.$t('services.service4'),
          description: this.$t('services.service4Description'),
          image: this.serviceImages[3],
        },
        {
          title: this.$t('services.service5'),
          description: this.$t('services.service5Description'),
          image: this.serviceImages[4],
        },
      ];
    },
    translatedAbout() {
      return [
        {
          title: this.$t('about.point1'),
          description: this.$t('about.point1Description'),
          image: this.aboutImages[0],
        },
        {
          title: this.$t('about.point2'),
          description: this.$t('about.point2Description'),
          image: this.aboutImages[1],
        },
        {
          title: this.$t('about.point3'),
          description: this.$t('about.point3Description'),
          image: this.aboutImages[2],
        },
      ];
    },
  },
  setup() {
    const emailStatus = ref(''); // Usar ref para manejar emailStatus de manera reactiva

    const contactForm = reactive({
      nombre: "",
      apellido: "",
      telefono: "",
      correo: "",
      mensaje: "",
    });


    const { executeRecaptcha, recaptchaLoaded } = useReCaptcha();

    const sendEmail = async () => {
      try {
        // Esperar a que reCAPTCHA esté listo (opcional)
        await recaptchaLoaded();

        // Ejecutar reCAPTCHA y obtener el token
        const token = await executeRecaptcha('contact');

        // Preparar los parámetros del correo, incluyendo el token de reCAPTCHA
        const templateParams = {
          from_name: contactForm.nombre,
          from_lastname: contactForm.apellido,
          from_phone: contactForm.telefono,
          from_email: contactForm.correo,
          message: contactForm.mensaje,
          "g-recaptcha-response": token,
          to_email: "info@stabilitexredovisning.se",
        };

        // Enviar el correo con emailjs
        emailjs.send('service_jk8pynx', 'template_ffbkdba', templateParams, 'hTQ5H-AEUov8xfwnb')
            .then(() => {
              emailStatus.value = 'success'; // Actualiza el estado a éxito
              Object.assign(contactForm, {
                nombre: '',
                apellido: '',
                telefono: '',
                correo: '',
                mensaje: ''
              });
              hideStatusAfterDelay();
            })
            .catch(() => {
              emailStatus.value = 'error'; // Actualizar el estado a error
              hideStatusAfterDelay();
            });

      } catch (error) {
        console.error("reCAPTCHA failed", error);
        emailStatus.value = 'error';
        hideStatusAfterDelay();
      }
    };
    const hideStatusAfterDelay = () => {
      setTimeout(() => {
        emailStatus.value = ''; // Restablecer el estado después de 5 segundos
      }, 5000);
    };
    return {
      emailStatus, // Exponer la variable reactiva
      contactForm, // Exponer contactForm para usarlo en la plantilla
      sendEmail,
      hideStatusAfterDelay
    };
  },
  methods: {
    scrollToSection(sectionId) {
      const section = document.getElementById(sectionId);
      if (section) {
        section.scrollIntoView({ behavior: 'smooth' });
      }
    },
    changeLanguage(language) {
      this.$i18n.locale = language;
      this.currentLanguage = language;  // Actualiza el idioma activo
    }

  }
}
</script>