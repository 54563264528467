<template>
  <div id="app">
    <PrincipalView />
  </div>
</template>

<script>
import PrincipalView from './components/principal.vue';


export default {
  name: 'App',
  components: {
    PrincipalView
  }
};
</script>

<style>
@import './Styles/index.css';
</style>