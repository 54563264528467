import { createI18n } from 'vue-i18n';

const messages = {
  en: {
    navbar: {
      home: 'Home',
      services: 'Services',
      about: 'About',
      contact: 'Contact',
    },
    header: {
      title: 'STABILITEX REDOVISNINGSBYRÅ AB',
      subtitle: 'Your digital partner in accounting and financial advice',
      description:
        'At Stabilitex Redovisningsbyrå AB we provide modern and flexible accounting services. With our experience and expertise, we help our clients navigate through complex financial landscapes and ensure that their business achieve its financial goals.',
    },
    services: {
      title: 'Our services:',
      service1: 'Bookkeeping and accounting',
      service1Description: 'We take care of all your bookkeeping so that you can focus on running your business. We make sure that all your financial transactions are recorded correctly and on time.',
      service2: 'Annual reports and financial statements',
      service2Description: 'We help you prepare annual reports and financial statements that meet all legal requirements and provide a clear picture of your company\'s financial situation.',
      service3: 'Tax advice',
      service3Description: 'We offer customized tax advice to help you optimize your tax situation and avoid unnecessary costs.',
      service4: 'Payroll administration',
      service4Description: 'We handle payroll, tax returns, and other payroll-related tasks to ensure that your employees receive the correct payment on time.',
      service5: 'Virtual financial advice',
      service5Description: 'Book virtual meetings with our experts to get help with budgeting, financing, and financial planning, all without having to leave your office.',
    },
    about: {
      title: 'Why choose us?',
      point1: 'Expertise and experience',
      point1Description: 'Our accountants have extensive experience and offer qualified advice and service, no matter where you are.',
      point2: 'Personalized service',
      point2Description: 'We take the time to understand your company\'s unique needs and offer tailored solutions that suit you.',
      point3: 'Reliability and accuracy',
      point3Description: 'We are accurate and reliable in our work, ensuring that you always receive correct and up-to-date financial information.',
    },
    contact: {
      title: 'Contact us today',
      description: 'Let us help you take control of your finances and focus on what you do best - running your business. Contact us today to book an initial meeting and see how we can help you achieve your financial goals.',
      name: 'Name',
      lastName: 'Last Name',
      phone: 'Phone',
      email: 'Email',
      message: 'Message',
      attachFile: 'Attach File',
      send: 'Send',
      contactNumber: 'Contact Number: +46 70 097 38 34',
      successMessage: 'Message sent successfully!',
      errorMessage: 'There was a problem sending the message.'
    },
  },
  sv: {
    navbar: {
      home: 'Hem',
      services: 'Tjänster',
      about: 'Om oss',
      contact: 'Kontakt',
    },
    header: {
      title: 'STABILITEX REDOVISNINGSBYRÅ AB',
      subtitle: 'Din digitala partner inom redovisning och ekonomisk rådgivning',
      description:
        'På Stabilitex Redovisningsbyrå AB är vi moderna och flexibla redovisningstjänster. Med vår erfarenhet och kompetens hjälper vi våra kunder att navigera genom komplexa ekonomiska landskap och säkerställa att deras företag når sina ekonomiska mål.',
    },
    services: {
      title: 'Våra tjänster:',
      service1: 'Bokföring och redovisning',
      service1Description: 'Vi tar hand om all din bokföring så att du kan fokusera på att driva ditt företag. Vi ser till att alla dina finansiella transaktioner registreras korrekt och i tid.',
      service2: 'Årsredovisning och bokslut',
      service2Description: 'Vi hjälper dig att upprätta årsredovisningar och bokslut som uppfyller alla lagkrav och ger en tydlig bild av ditt företags ekonomiska situation.',
      service3: 'Skatterådgivning',
      service3Description: 'Vi erbjuder skräddarsydd skatterådgivning för att hjälpa dig optimera din skattesituation och undvika onödiga kostnader.',
      service4: 'Löneadministration',
      service4Description: 'Vi hanterar löneutbetalningar, skattedeklarationer och andra lönerelaterade uppgifter för att säkerställa att dina anställda får rätt ersättning i tid.',
      service5: 'Virtuell ekonomisk rådgivning',
      service5Description: 'Boka virtuella möten med våra experter för att få hjälp med budgetering, finansiering och ekonomisk planering, helt utan att behöva lämna ditt kontor.',
    },
    about: {
      title: 'Varför välja oss?',
      point1: 'Expertis och erfarenhet',
      point1Description: 'Våra redovisningskonsulter har lång erfarenhet och erbjuder kvalificerad rådgivning och service, oavsett var du befinner dig.',
      point2: 'Personlig service',
      point2Description: 'Vi tar oss tid att förstå ditt företags unika behov och erbjuder skräddarsydda lösningar som passar just dig.',
      point3: 'Pålitlighet och noggrannhet',
      point3Description: 'Vi är noggranna och pålitliga i vårt arbete, vilket säkerställer att du alltid får korrekt och aktuell ekonomisk information.',
    },
    contact: {
      title: 'Kontakta oss idag',
      description: 'Låt oss hjälpa dig att ta kontroll över din ekonomi och fokusera på det du gör bäst – att driva ditt företag. Kontakta oss idag för att boka ett första möte och se hur vi kan hjälpa dig att nå dina ekonomiska mål.',
      name: 'Namn',
      lastName: 'Efternamn',
      phone: 'Telefon',
      email: 'E-post',
      message: 'Meddelande',
      attachFile: 'Bifoga fil',
      send: 'Skicka',
      contactNumber: 'Kontaktnummer: +46 70 097 38 34',
      successMessage: 'Meddelandet skickades!',
      errorMessage: 'Det uppstod ett problem när meddelandet skickades.'
    },
  },
  ru: {
    navbar: {
      home: 'Главная',
      services: 'Услуги',
      about: 'О нас',
      contact: 'Контакты',
    },
    header: {
      title: 'STABILITEX REDOVISNINGSBYRÅ AB',
      subtitle: 'Ваш цифровой партнер по бухгалтерскому учету и финансовому консультированию',
      description:
        'Stabilitex Redovisningsbyrå AB предоставляет бухгалтерские услуги на современной и гибкой основе. Благодаря нашему опыту и знаниям, мы помогаем нашим клиентам ориентироваться в сложных экономических стратегиях и обеспечивать достижение финансовых целей.',
    },
    services: {
      title: 'Наши услуги:',
      service1: 'Бухгалтерский учет',
      service1Description: 'Мы берем на себя всю вашу бухгалтерию, чтобы вы могли сосредоточиться на управлении своим бизнесом. Мы следим за тем, чтобы все ваши финансовые операции были правильно и своевременно зафиксированы.',
      service2: 'Годовые отчеты и финансовая отчетность',
      service2Description: 'Мы помогаем вам подготовить годовые отчеты и финансовую отчетность, которые соответствуют всем юридическим требованиям и дают четкое представление о финансовом состоянии вашей компании.',
      service3: 'Налоговое консультирование',
      service3Description: 'Мы предлагаем индивидуальные налоговые консультации, чтобы помочь вам оптимизировать ваше налоговое положение и избежать ненужных расходов.',
      service4: 'Администрирование заработной платы',
      service4Description: 'Мы занимаемся расчетом заработной платы, декларациями работодателя и другими задачами, связанными с заработной платой, чтобы ваши сотрудники получали правильное вознаграждение за свой труд вовремя.',
      service5: 'Виртуальные финансовые консультации',
      service5Description: 'Запланируйте виртуальные встречи с нашими экспертами, чтобы получить помощь в составлении бюджета, финансировании и финансовом планировании, не покидая своего офиса.',
    },
    about: {
      title: 'Почему следует выбрать именно нас?',
      point1: 'Экспертность и опыт',
      point1Description: 'Наши бухгалтеры имеют богатый опыт и предлагают квалифицированные консультации и услуги, независимо от того, где вы находитесь.',
      point2: 'Персональное обслуживание',
      point2Description: 'Мы уделяем время именно вам и вашему бизнесу, чтобы понять уникальные потребности вашей компании и предлагаем индивидуальные решения, которые подходят вам и вашему бизнесу.',
      point3: 'Надежность и точность',
      point3Description: 'Мы тщательно и верно выполняем свою работу, гарантируя, что вы всегда будете получать точную и актуальную финансовую информацию.',
    },
    contact: {
      title: 'Свяжитесь с нами сегодня',
      description: 'Позвольте нам помочь вам взять под контроль ваши финансы и сосредоточиться на том, что вы делаете лучше всего - управлении вашим бизнесом. Свяжитесь с нами сегодня, чтобы записаться на первую встречу и узнать, как мы можем помочь вам достичь ваших финансовых целей.',
      name: 'Имя',
      lastName: 'Фамилия',
      phone: 'Телефон',
      email: 'Эл. почта',
      message: 'Сообщение',
      attachFile: 'Прикрепить файл',
      send: 'Отправить',
      contactNumber: 'Контактный номер: +46 70 097 38 34',
      successMessage: 'Сообщение отправлено успешно!',
      errorMessage: 'Произошла ошибка при отправке сообщения.'
    },
  },
  es: {
    navbar: {
      home: 'Inicio',
      services: 'Servicios',
      about: 'Sobre nosotros',
      contact: 'Contacto',
    },
    header: {
      title: 'STABILITEX REDOVISNINGSBYRÅ AB',
      subtitle: 'Su socio digital en contabilidad y asesoramiento financiero',
      description:
        'En Stabilitex Redovisningsbyrå AB, nos dedicamos a proporcionar servicios contables modernos y flexibles. Con nuestra experiencia y conocimientos, ayudamos a nuestros clientes a navegar por paisajes financieros complejos y aseguramos que sus negocios alcancen sus objetivos financieros.',
    },
    services: {
      title: 'Nuestros servicios:',
      service1: 'Contabilidad',
      service1Description: 'Nos encargamos de toda su contabilidad para que pueda concentrarse en dirigir su negocio. Nos aseguramos de que todas sus transacciones financieras se registren correctamente y a tiempo.',
      service2: 'Informes anuales y estados financieros',
      service2Description: 'Le ayudamos a preparar informes anuales y estados financieros que cumplan con todos los requisitos legales y proporcionen una imagen clara de la situación financiera de su empresa.',
      service3: 'Asesoramiento fiscal',
      service3Description: 'Ofrecemos asesoramiento fiscal personalizado para ayudarle a optimizar su situación fiscal y evitar costos innecesarios.',
      service4: 'Administración de nóminas',
      service4Description: 'Nos encargamos de las nóminas, las declaraciones de impuestos y otras tareas relacionadas con las nóminas para garantizar que sus empleados reciban la compensación correcta a tiempo.',
      service5: 'Asesoramiento financiero virtual',
      service5Description: 'Reserve reuniones virtuales con nuestros expertos para obtener ayuda con la planificación presupuestaria, financiera y económica, sin tener que salir de su oficina.',
    },
    about: {
      title: '¿Por qué elegirnos?',
      point1: 'Experiencia y conocimientos',
      point1Description: 'Nuestros contadores tienen una amplia experiencia y ofrecen asesoramiento y servicios calificados, sin importar dónde se encuentre.',
      point2: 'Servicio personalizado',
      point2Description: 'Nos tomamos el tiempo para entender las necesidades únicas de su empresa y ofrecemos soluciones personalizadas que se adaptan a usted.',
      point3: 'Fiabilidad y precisión',
      point3Description: 'Somos meticulosos y confiables en nuestro trabajo, lo que garantiza que siempre recibirá información financiera correcta y actualizada.',
    },
    contact: {
      title: 'Contáctenos hoy',
      description: 'Permítanos ayudarle a tomar el control de sus finanzas y concentrarse en lo que mejor hace: dirigir su negocio. Contáctenos hoy para reservar una reunión inicial y ver cómo podemos ayudarle a alcanzar sus objetivos financieros.',
      name: 'Nombre',
      lastName: 'Apellido',
      phone: 'Teléfono',
      email: 'Correo',
      message: 'Mensaje',
      attachFile: 'Adjuntar archivo',
      send: 'Enviar',
      contactNumber: 'Número de contacto: +46 70 097 38 34',
      successMessage: 'Mensaje enviado con éxito!',
      errorMessage: 'Hubo un problema al enviar el mensaje.'
    },
  },
};

const i18n = createI18n({
  locale: 'sv', // Configuración inicial del idioma
  messages,
});

export default i18n;